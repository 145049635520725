import React from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import SelectGameModeContainer from '../../containers/SelectGameModeContainer/SelectGameModeContainer';
import GameDetailsContainer from '../../containers/GameDetailsContainer';
import styles from './GameModePage.module.css';

const GameModePage: React.FC = () => {
  return (
    <>
      <div className={styles.questionPage}>
        <Container>
          <Row>
            <Col>
              <SelectGameModeContainer />
            </Col>
          </Row>
        </Container>
        <div className={styles.gameDetails}>
          <Container>
            <Row>
              <Col>
                <GameDetailsContainer />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default GameModePage;
